import { TextField, withStyles } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FieldRendererProps } from "./FieldRenderer";

export const StyledTextInput = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "var(--main-bg-bot_primary_color)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--main-bg-bot_primary_color)",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--main-bg-bot_primary_color)",
      },
    },
  },
})(TextField);

const FormTextField: React.FC<FieldRendererProps> = (
  ({ field }) => {
    const { control } = useFormContext();
    return (
      <Controller control={control} name={field.key} defaultValue={""} rules={{
        required: field.required,
      }} render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <StyledTextInput
          onChange={onChange}
          value={value}
          aria-label={field.label}
          label={field.label}
          placeholder={field.placeholder}
          variant="outlined"
          style={{ width: "100%" }}
          error={invalid}
          helperText={error && error.message}
        />)}
      />
    );
  }
);

export default FormTextField;
