import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Portal } from "../../../utils/Portal";
import { RatingHeads } from "./RatingBlob";

import styles from "./RatingOverlay.module.scss";

type Rating = {
  message: string;
  rating: number;
};

export interface RatingOverlayProps {
  isOpen: boolean;
  onClose: (rating?: Rating) => void;
  initialRating: 1 | 2 | 3;
  texts: {
    bad: string;
    medium: string;
    good: string;
  };
}

export const RatingOverlay: React.FC<RatingOverlayProps> = ({
  isOpen,
  onClose,
  initialRating,
  texts,
}) => {
  const [rating, setRating] = useState(initialRating);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setRating(initialRating);
  }, [initialRating]);

  const close = useCallback(
    (obj?: Rating) => {
      onClose(obj);
    },
    [onClose]
  );

  if (!isOpen) return null;

  const description =
    rating === 1 ? texts.bad : rating === 2 ? texts.medium : texts.good;

  return (
    <Portal>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <RatingHeads
                  onClick={(rating) => setRating(rating)}
                  selected={rating}
                />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "16px" }}>
              <Typography variant="body1" style={{ fontSize: "1em" }}>
                {description}
              </Typography>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={styles.input}
                placeholder="Uddyb her..."
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button className={styles.cancelBtn} onClick={() => close()}>
                Fortryd vurdering
              </Button>
              <Button
                className={styles.submitBtn}
                variant={"outlined"}
                style={{ borderRadius: "3px" }}
                onClick={() => {
                  close({
                    rating,
                    message,
                  });
                }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Portal>
  );
};
