import React, {Component} from 'react';
import './Header.scss';
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal";
import SaveDialogModal from "./modals/SaveDialogModal";
import validateEmail from "./utils/ValidateEmail";
import Logger from "../../utils/Logger";
import {OverflowIcon} from "../Message/components/icons/OverflowIcon";
import GdprModal from "./modals/GdprModal";

type HeaderComponentProps = {
    deleteDialog: any;
    botName: string;
    sendDialog: any;
    addPopup: any;
}

class HeaderComponent extends Component<HeaderComponentProps, any> {

    state = {
        iconAnchor: null,
        isOpen: false,
        isConfirmDeleteOpen: false,
        isSaveModalOpen: false,
        isGdprOpen: false,
        userEmail: "",
        isEmailValid: true,
        isModalLoading: false
    };

    dropdownAnchor: any;

    constructor(props: HeaderComponentProps) {
        super(props);
        this.dropdownAnchor = React.createRef();
    }

    handleClick = (e: any) => {
        this.setState({
            iconAnchor: e.target,
            isOpen: true
        });
    };

    handleDeleteConversation = async () => {
        try{
            await this.props.deleteDialog();
            this.props.addPopup("Din samtale bliver slettet.", "success");
        }
        catch(e){
            Logger.debug("Failed deleting this dialog", e);
            this.props.addPopup("Der opstod en fejl under forespørgslen om at slette din samtale. Prøv venligst igen.", "error");
        }

        this.setState({ isConfirmDeleteOpen: false});
    };

    handleSendConversation = async () => {
        // Do call to backend with email address here
        let isEmailValid: boolean = validateEmail(this.state.userEmail);
        if(isEmailValid){
            this.setState({ isModalLoading: true });
            try {
                await this.props.sendDialog(this.state.userEmail);
                this.props.addPopup("Du vil modtage samtalen på email inden længe.", "success");
            }
            catch (e) {
                Logger.debug("Sending dialog failed, please alert user");
                this.props.addPopup("Der opstod en fejl under forespørgslen om at få tilsendt samtalen via email. Prøv venligst igen.", "error");
            }
            this.setState({ isModalLoading: false });
            this.toggleSendEmailModal();
        }
        else {
            this.setState({ isEmailValid: false })
        }
    };

    onEmailChange = (e: any) => {
        this.setState({
            userEmail: e.target.value
        })
    };

    toggleSendEmailModal = () => {
        this.handleClose();
        this.setState({
            isSaveModalOpen: !this.state.isSaveModalOpen
        })
    };

    toggleConfirmDeleteModal = () => {
        this.handleClose();
        this.setState({
            isConfirmDeleteOpen: !this.state.isConfirmDeleteOpen
        })
    };

    toggleGdprModal = () => {
        this.handleClose();
        this.setState({
            isGdprOpen: !this.state.isGdprOpen
        })
    };

    handleClose = () => {
        this.setState({
            iconAnchor: null,
            isOpen: false
        });
    };

    render() {
        return (
            <Grid container className="content-header" direction="row">
                <Grid item>
                    <IconButton
                        aria-label="Mere"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        color={"primary"}
                        className={"MenuButton"} >
                        <OverflowIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <div className={"chatbotName"}>{(this.props.botName && this.props.botName !== "" ? this.props.botName : "Muni" )}</div>
                </Grid>
                <Menu
                    anchorEl={this.state.iconAnchor}
                    open={this.state.isOpen}
                    onClose={this.handleClose} >
                    <MenuItem onClick={this.toggleSendEmailModal}>
                        Send samtale
                    </MenuItem>
                    <MenuItem onClick={this.toggleConfirmDeleteModal}>
                        Slet samtale
                    </MenuItem>
                    <MenuItem onClick={this.toggleGdprModal}>
                        Om persondata
                    </MenuItem>
                </Menu>
                <ConfirmDeleteModal open={this.state.isConfirmDeleteOpen}
                                    handleDelete={this.handleDeleteConversation}
                                    closeModal={this.toggleConfirmDeleteModal}/>
                <SaveDialogModal open={this.state.isSaveModalOpen}
                                 toggleClose={this.toggleSendEmailModal}
                                 sendEmail={this.handleSendConversation}
                                 onEmailChange={this.onEmailChange}
                                 isValid={this.state.isEmailValid}
                                 isLoading={this.state.isModalLoading}/>
                 <GdprModal open={this.state.isGdprOpen} toggleClose={this.toggleGdprModal} />
            </Grid>
        );
    }
}

export default HeaderComponent;