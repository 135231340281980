import {config} from "../config/config";
import * as Logger from 'loglevel';

if(config.isProduction){
    Logger.setLevel(Logger.levels.SILENT);
}
else{
    Logger.setLevel(Logger.levels.TRACE);
}

export default Logger;