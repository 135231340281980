import React from 'react';
import {Grid} from "@material-ui/core";
import moment from "moment";

type UserBlobProps = {
    text?: string
    timestamp?: string
}

export const UserBlob = (props: UserBlobProps) => {
        return (
            <Grid container direction="row-reverse" aria-hidden="true">
                <Grid item xs={12} className="UserBlob" key={0}>
                    <div id={"UserBlobPaper"}>
                        <span className="sr-only">Sendt klokken {moment().format("HH:mm")} fra dig. </span>
                        {props.text}
                    </div>
                </Grid>
                <Grid item aria-hidden="true" tabIndex={-1} key={1}>
                    <UserBlobTimestamp timestamp={moment().format("HH:mm")}/>
                </Grid>
            </Grid>

        );
};

export const UserBlobTimestamp = ({timestamp}: UserBlobProps) => {
    return(
        <div className="UserTimestamp">{timestamp ? timestamp : ""}</div>
    )
};

export default UserBlob;