import {config} from "../../config/config";


export class Store {

    public static storeSession(value: string, municipalityCode: number) {

        const sessionKey = `session:${municipalityCode}`;
        const currentSession = Store.get(sessionKey);

        if(!currentSession || currentSession !== value) {
            Store.store(sessionKey, value);
        }

        Store.store(`expire:${municipalityCode}`, Date.now());
    }

    public static storeCustomerId(value: string, municipalityCode: number) {

        const key = `customerId:${municipalityCode}`;
        const currentSession = Store.get(key);

        if(!currentSession || currentSession !== value) {
            Store.store(key, value);
        }
    }

    public static storeFeedback(hasFeedback: boolean, municipalityCode: number) {

        const key = `feedback:${municipalityCode}`;
        const currentSession = Store.get(key);

        if(!currentSession || currentSession !== hasFeedback) {
            Store.store(key, hasFeedback);
            Store.store(`feedback:expire:${municipalityCode}`, Date.now());
        }
    }

    /**
     * Get the stored session. If the session is expired after inactivity the session is return as empty string
     * @param municipalityCode
     */
    public static getSession(municipalityCode: number): string {

        const sessionKey = `session:${municipalityCode}`;
        const expireKey = `expire:${municipalityCode}`;

        const expireTime: number = Store.get(expireKey);

        if ((Date.now() - expireTime) > config.sessionExpire) {
            return ""; // Session has expired
        }

        const session = Store.get(sessionKey);

        return !session ? "" : session;
    }

    /**
     * Return true if feedback has been asked for the given municipality
     * @param municipalityCode
     */
    public static hasFeedback(municipalityCode: number): boolean {

        const key = `feedback:${municipalityCode}`;

        const hasFeedbackBeenGiven = Store.get(key);

        if (hasFeedbackBeenGiven !== "true")
            return false;

        const feedbackExpireKey = `feedback:expire:${municipalityCode}`;
        const expireTime: number = Store.get(feedbackExpireKey);

        if ((Date.now() - expireTime) > config.feedbackExpire) {
            Store.remove(municipalityCode, "feedback");
            Store.remove(municipalityCode, `feedback:expire`);
            return false; // Feedback has expired
        }

        return true;
    }

    /**
     * General helper function to store data
     * @param key
     * @param value
     */
    public static store(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    /**
     * General helper function to get data
     * @param key
     * @param value
     */
    public static get(key: string): any {
        return localStorage.getItem(key)
    }

    /**
     * General helper function to delete data
     * @param key
     * @param value
     */
    public static remove(municipalityCode: number, prefix: string) {
        localStorage.removeItem(`${prefix}:${municipalityCode}`);
    }

}