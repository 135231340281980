import { CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DAWAAutocompleteResponse,
  DAWAService,
} from "../../../../service/DAWAService";
import { FieldRendererProps } from "./FieldRenderer";
import debounce from "lodash.debounce";
import { StyledTextInput } from "./FormTextField";
import { IAddressTextField } from "../../../../model/API/FormActionSchema";
import { Controller, useFormContext } from "react-hook-form";

export const FormAddressField = React.forwardRef<
  HTMLInputElement,
  FieldRendererProps
>(({ field }, ref) => {

  const { control, watch } = useFormContext();
  const value = watch(field.key);

  const [innerValue, setInnerValue] = useState(
    value && value.tekst ? value.tekst : ""
  );
  const [loading, setLoading] = useState(false);
  const [autocompleteResult, setAutocompleteResult] =
    useState<DAWAAutocompleteResponse>();

  const dawaService = useMemo(() => new DAWAService(), []);

  const changeQuery = useCallback(
    async (v: string) => {
      try {
        setLoading(true);
        const result = await dawaService.autocomplete(v);
        setAutocompleteResult(result);
      } catch {
      } finally {
        setLoading(false);
      }
    },
    [dawaService, setLoading]
  );

  const debouncedCallback = useMemo(
    () => debounce(changeQuery, 200),
    [changeQuery]
  );

  useEffect(() => {
    // Unregister the debounce
    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback]);

  useEffect(() => {
    // When the value changes we need to change the inner value
    setInnerValue(value && value.tekst ? value.tekst : "");
  }, [value]);

  const validate = (value: DAWAAutocompleteResponse[0] | null): boolean => {
    const castedField = field as IAddressTextField;
    return value ? (!castedField.dawaTypes || castedField.dawaTypes.indexOf(value.type) > -1) : false;
  }

  return (
    <Controller control={control} name={field.key} defaultValue={null} rules={{
      required: field.required,
    }} render={({
      field: { onChange, value },
      fieldState: { invalid, error },
    }) => (
      <Autocomplete<DAWAAutocompleteResponse[0]>
        aria-label={field.label}
        options={autocompleteResult || []}
        inputValue={innerValue as string}
        value={value}
        onChange={(event, value) => {
          if (validate(value)) {
            onChange(value ? value : innerValue);
          }
        }}
        onInputChange={(event, v) => {
          setInnerValue(v);
          debouncedCallback(v);
        }}
        loading={loading}
        style={{ width: "100%" }}
        onClose={() => setLoading(false)}
        getOptionLabel={(option) => option.forslagstekst || option.tekst}
        getOptionSelected={(option, value) =>
          value && option.tekst === value.tekst && option.type === value.type
        }
        noOptionsText={
          !innerValue
            ? "Ingen adresser at vise"
            : "Den angivne adresse kunne desværre ikke findes"
        }
        renderInput={(params) => (
          <StyledTextInput
            {...params}
            error={invalid}
            helperText={error && error.message}
            label={field.label}
            placeholder={field.placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    )} />
  );
});
