import { config } from "../config/config";
import axios, { AxiosError } from "axios";
import { DialogRequest } from "../model/API/request/DialogRequest";
import Logger from "../utils/Logger";
import { DialogResponse } from "../model/API/response/DialogResponse";

export class APIService {
  private static readonly baseUrls = config.backend.hosts;
  private static currentHost: number =
    Math.round(Math.random()) % APIService.baseUrls.length;

  /**
   * Returns the current active host
   */
  public getActiveHost(): string {
    return APIService.baseUrls.length > 0
      ? APIService.baseUrls[APIService.currentHost]
      : "";
  }

  /**
   * Returns a random host
   */
  public determineHost(): number {
    return Math.floor(Math.random() * APIService.baseUrls.length);
  }

  /**
   * Sets current host to value
   * @param host
   */
  public setHost(host: number): void {
    APIService.currentHost = this.determineHost();
    Logger.debug(
      `Setting host to: ${APIService.baseUrls[APIService.currentHost]}`
    );
  }

  /**
   * Receive the initial display message of a given category for the municipality.
   */
  public async getCategoryMessage(
    msg: CategoryMessage
  ): Promise<DialogResponse> {
    const req: DialogRequest = {
      sessionId: msg.sessionId,
      customerId: "",
      category: msg.category,
      municipalityCode: msg.municipalityCode,
      msg: "",
      targetClient: "web",
    };

    let res: any;
    try {
      res = await this.sendMessage(req);
      return res.data;
    } catch (e) {
      Logger.error("Error while sending user message: ", e);
      throw e;
    }
  }

  /**
   * Sends a user message to the chatbot
   * @param msg
   */
  public async sendUserMessage(msg: UserMessage): Promise<DialogResponse> {
    let message = "";

    if (msg.sessionId === "" && (msg.msg === "" || msg.msg === "Velkomst")) {
      message = "";
    } else {
      message = (msg.msg ? msg.msg.trim() : msg.msg) || "Velkomst";

      if (message.length > 300)
        // Handle too long a message
        message = message.substr(0, 300);
    }

    const req: DialogRequest = {
      sessionId: msg.sessionId,
      customerId: msg.customerId,
      municipalityCode: msg.municipalityCode,
      msg: message,
      targetClient: "web",
    };

    try {
      const res = await this.sendMessage(req);
      return res.data;
    } catch (e) {
      Logger.error("Error while sending user message: ", e);
      throw e;
    }
  }

  /**
   * Send an user action with a set of values.
   * @param action the action to submit
   * @returns a dialog response
   */
  public async sendUserAction(action: UserAction): Promise<DialogResponse> {
    try {
      return await this.performRequest(async () => {
        return await axios
          .post(`${this.getActiveHost()}/api/action`, {
            ...action,
            targetClient: "web",
          })
          .then((res) => res.data);
      });
    } catch (e) {
      Logger.error("Error while sending user action: ", e);
      throw e;
    }
  }

  public async postStatisticalEvent(event: StatisticalEvent): Promise<void> {
    try {
      Logger.debug("Posting stat event", event);
      await axios.post(`${this.getActiveHost()}/api/stat-events`, event);
    } catch (e) {
      Logger.error("Could not post statistical event: ", e);
      // We should NOT THROW AN ERROR but instead fail sailently.
    }
  }

  /**
   * Queue the deletion of the dialog.
   */
  public async deleteDialog(req: DeleteDialog): Promise<any> {
    try {
      return await this.performRequest(async () => {
        const res = await axios.delete(`${this.getActiveHost()}/api/dialog`, {
          data: { ...req },
        });
        return res.data;
      });
    } catch (e) {
      Logger.error("Error while deleting dialog: ", e);
      throw e;
    }
  }

  /**
   * Queue a delivery of the dialog in a mail.
   */
  public async sendDialog(req: SendDialog): Promise<any> {
    try {
      return await this.performRequest(async () => {
        const res = await axios.post(`${this.getActiveHost()}/api/mail`, req);
        return res.data;
      });
    } catch (e) {
      Logger.error("Error while sending user message: ", e);
      throw e;
    }
  }

  /**
   * Rate the current dialog.
   */
  public async rateDialog(req: RateDialog): Promise<any> {
    try {
      return await this.performRequest(async () => {
        const res = await axios.post(
          `${this.getActiveHost()}/api/dialog/rating`,
          req
        );
        return res.data;
      });
    } catch (e) {
      Logger.error("Error while rating dialog ", e);
      throw e;
    }
  }

  /**
   * Sync presentation settings.
   * @param municipalityCode
   */
  public async syncPresentationSettings(
    municipalityCode: number
  ): Promise<any> {
    try {
      return await this.performRequest(async () => {
        const res = await axios.get(
          `${this.getActiveHost()}/api/maintenance/${municipalityCode}`,
          {}
        );

        if (res.data) {
          config.inactivityTimeInSeconds = res.data.feedbackDelay || 25;
          config.isFeedbackEnabled = res.data.isFeedbackEnabled;
          config.sessionExpire = res.data.sessionDuration || 15 * 60 * 1000;
        }
      });
    } catch (e) {
      Logger.error("Error while getting maintenance mode: ", e);
    }
  }

  /**
   * Sends a general message. Supports both the category message and a normal message
   * @param req
   */
  protected async sendMessage(req: DialogRequest): Promise<any> {
    return await this.performRequest(async () => {
      return await axios.post(`${this.getActiveHost()}/api/dialog`, req);
    });
  }

  /**
   * Sends a provided request and retries other hosts if available.
   * @param req
   */
  protected async performRequest(req: () => Promise<any>): Promise<any> {
    let error = undefined;
    for (let i = 0; i < APIService.baseUrls.length; i++) {
      try {
        Logger.debug(`Sending req to host ${this.getActiveHost()}`);
        return await req();
      } catch (e) {
        error = e;
        if (this.isFailoverCandidate(e as any)) {
          // Do failover
          Logger.debug(
            `Do failover length ${APIService.baseUrls.length} currentHost ${APIService.currentHost + 1
            }`
          );
          APIService.currentHost =
            (APIService.currentHost + 1) % APIService.baseUrls.length;
          Logger.debug(`Set host to ${APIService.currentHost}`);
          Logger.debug(`Host is ${this.getActiveHost()}`);
        } else {
          Logger.debug(`Not a failover candicate ${e}`);
          throw e; // General error
        }
      }
    }

    throw error;
  }

  /**
   * Determines if recieved error can be solved by a failover
   * @param e
   */
  protected isFailoverCandidate(e: AxiosError): boolean {
    return (
      e.response?.status === 404 ||
      e.response?.status === 502 ||
      this.isNetworkError(e)
    );
  }

  /**
   * Checks if the error is caused by network issue
   * @param e
   */
  protected isNetworkError(e: AxiosError): boolean {
    return !!e.isAxiosError && !e.response;
  }
}

export interface UserMessage {
  sessionId?: string;
  readonly msg: string;
  readonly municipalityCode: number;
  readonly customerId: string;
}

export interface UserAction extends Omit<UserMessage, "msg"> {
  readonly action: string;
  readonly resultVariable: string;
  readonly value: any;
}

export interface CategoryMessage {
  sessionId?: string;
  readonly municipalityCode: number;
  readonly category: string;
}

export interface StatisticalEvent {
  eventType: "wd-search" | "link-click";
  municipalityCode: number;
  metadata: Record<string, any>;
  sessionId: string;
}

export interface BaseDialog {
  readonly sessionId: string;
  readonly municipalityCode: number;
  readonly customerId: string;
}

export interface SendDialog extends BaseDialog {
  readonly mail: string;
}

export interface DeleteDialog extends BaseDialog { }

export interface RateDialog extends BaseDialog {
  readonly rating: number;
  readonly comment: string;
  readonly tag?: string;
}
