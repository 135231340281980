import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

type GdprModalProps = {
    open: boolean;
    toggleClose: () => any;
}

class GdprModal extends Component<GdprModalProps, any> {
    render() {
        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.toggleClose} aria-labelledby="form-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="form-dialog-title">Information vedrørende personfølsomme oplysninger</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <h4>Enhedsinformation</h4>
                            Din IP-adresse og browser agent type krypteres og anonymiseres, således samtaler foretaget via chatbot-løsningen ikke kan spores tilbage til dig.

                            <h4>Data anvendelse og formål</h4>
                            Samtaler foretaget i chatbot-løsningen, vil blive lagret op til 90 dage. Samtalen vil derefter slettes. Opbevaring af samtaler har til formål at forbedre løsningen i forhold til dialog og svareffektiviteten.
                            <br/><br/>
                            Følsomme oplysninger (fx om dit helbred) og fortrolige oplysninger (fx CPR-nummer) bør ikke indtastes i systemet. Såfremt dette gøres, vil oplysningerne blive slettet sammen med samtalen efter 90 dage.

                            <h4>Dataansvarlig</h4>
                            Den Digitale Hotline (DDH) er ansvarlige for anvendelsen samt opbevaring af data.

                            <h4>Slet samtale</h4>
                            Samtaler kan slettes manuelt ved at trykke på de tre prikker øverst i venstre hjørne. Vælg ”Slet samtale” og fuldfør derefter sletningen af samtale historikken. Herefter vil samtalen blive slettet i systemet. Der kan gå optil 1 dag efter anmodningen før sletningen er foretaget.

                            <h4>Cookies</h4>
                            Der anvendes tekniske cookies i chatbot-løsningen. Disse cookies
                            indsamler ikke oplysninger om dig.

                            <br/><br/><br/>
                            Har du yderligere spørgsmål vedrørende opbevaring af personoplysninger, kan du kontakte den pågældende kommune.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.toggleClose} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default GdprModal;