import React, {Component} from 'react';
import {Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';


type PopupSnackbarProps = {
    text: string,
    type: string
}

class PopupSnackbar extends Component<PopupSnackbarProps> {

    state = {
        isOpen: true
    };


    handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            isOpen: false
        })
    };

    render() {
        return (
            <div>
                <Snackbar
                    open={this.state.isOpen}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    >
                    <MuiAlert severity={this.props.type === "success" ? "success" : "error"} onClose={this.handleClose}>
                        {this.props.text}
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }
}

export default PopupSnackbar;