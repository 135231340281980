export const config = {
    isTest: (process.env.REACT_APP_ENV_MODE === "test" ? true : false),
    isProduction: (process.env.REACT_APP_ENV_MODE === "prod" ? true : false),
    isDev: (process.env.REACT_APP_ENV_MODE === "dev" ? true : false),
    backend: {
        hosts: process.env.REACT_APP_BACKEND_HOSTS ? process.env.REACT_APP_BACKEND_HOSTS.split(",") : []
    },
    typingTime: 500,
    inactivityTimeInSeconds: 30,
    sessionExpire: 900000, // 15 min
    feedbackExpire: 3600000, // 1 hour
    reviewPossibilities: [
        "Fik du hjælp til dit spørgsmål?",
        // "Gjorde jeg det godt eller dårligt? Alt ris og ros er velkommen - så jeg vil blive glad for, hvis du bruger et minut på at vurdere vores samtale.",
        // "Fik du hjælp til dit spørgsmål? Jeg gennemgår nemlig stadig en læringsproces, så jeg sætter stor pris på feedback. Vil du bruge et minut på at vurdere vores samtale?",
        // "Fortæl mig gerne hvordan du synes samtalen er forløbet, så ved jeg nemlig om jeg har hjulpet dig eller ej. Det tager under et minut at vurdere.", 
        // "Har du lyst til at bruge et minut på at vurdere vores samtale, din mening er nemlig super vigtig for min læringsproces. Alt ris og ros er mere end velkommen."
    ],
    sessionExpiredText: "Sessionen er udløbet. Du kan stille mig et nyt spørgsmål, hvis du har brug for hjælp.",
    isFeedbackEnabled: true,
};