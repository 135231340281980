import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./InputComponent.scss";
import { ChatbotViewSubmitCallback } from "../../views/ChatbotView";
import { Box } from "@material-ui/core";

type InputComponentProps = {
  onSubmit: ChatbotViewSubmitCallback;
  disabled: boolean;
  placeholder?: string;
};

class InputComponent extends Component<InputComponentProps> {
  state = {
    inputValue: "",
    setValue: "",
  };

  handleKeyDown = (event: any) => {
    if (
      event.key === "Enter" &&
      !this.props.disabled &&
      this.state.inputValue &&
      this.state.inputValue.trim() !== ""
    ) {
      this.handleOnClick();
    }
  };

  handleOnClick = () => {
    if (
      this.state.inputValue &&
      this.state.inputValue.trim() !== "" &&
      !this.props.disabled
    ) {
      this.props.onSubmit(this.state.inputValue);
      this.setState({ inputValue: "" });
    }
  };

  handleChange = (e: any) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  render() {
    return (
      <Box className="inputBox">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Box
            className="input-wrapper"
            width="100%"
            flexGrow="1"
            position="relative"
          >
            <input
              tabIndex={0}
              className="userInput"
              id="outlined-size-normal"
              value={this.state.inputValue}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              aria-label={this.props.placeholder || "Stil mig et spørgsmål..."}
              placeholder={this.props.placeholder || "Stil mig et spørgsmål..."}
            />
          </Box>

          <Button
            className="submit"
            onKeyDown={this.handleKeyDown}
            onClick={this.handleOnClick}
            disabled={this.props.disabled}
            variant={"outlined"}
          >
            Send
          </Button>
        </Box>
      </Box>
    );
  }
}

export default InputComponent;
