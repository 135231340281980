import React from "react";
import {
  FormField,
  FormFieldType,
} from "../../../../model/API/FormActionSchema";
import { FormAddressField } from "./FormAddressField";
import FormTextField from "./FormTextField";

export interface FieldRendererProps {
  field: FormField;
}

const renderers: { [key in FormFieldType]: React.FC<FieldRendererProps> } = {
  [FormFieldType.TEXT_FIELD]: FormTextField,
  [FormFieldType.ADDRESS_FIELD]: FormAddressField,
  [FormFieldType.NUMBER_FIELD]: () => <>A number field</>,
};

export const FieldRenderer: React.FC<{
  field: FormField;
  onChange: (value: string | number | boolean) => void;
  value: string | number | boolean;
}> = ({ field, value, onChange }) => {
  const FieldComponent = renderers[field.type];
  if (!FieldComponent) {
    return <></>;
  }
  return <FieldComponent field={field} />;
};
