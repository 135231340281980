import * as React from "react";

type ExpireProps = {
    delay?: number;
}

export default class Expire extends React.Component<ExpireProps> {

    _timer: any;
    state = {visible:true};
    expireTime = this.props.delay || 0;


    componentWillReceiveProps(nextProps: any) {
        // reset the timer if children are changed
        if (nextProps.children !== this.props.children) {
            this.setTimer();
            this.setState({visible: true});
        }
    }

    componentDidMount = () => {
        this.setTimer();
    };

    setTimer = () => {
        // clear any existing timer
        if (this._timer != null) {
            clearTimeout(this._timer)
        }

        // hide after `delay` milliseconds
        this._timer = setTimeout(() => {
            this.setState({visible: false});
            this._timer = null;
        }, this.props.delay);
    };

    componentWillUnmount = () => {
        clearTimeout(this._timer);
    };

    render = () => {
        return this.state.visible ? this.props.children : false
    }
}