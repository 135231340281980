import { Grid, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { FormActionSchema } from "../../../../model/API/FormActionSchema";
import { FormAction } from "../../../../model/API/MessageElement";
import { ChatbotViewSubmitCallback } from "../../../../views/ChatbotView";
import Delayed from "../../../utils/Delayed";
import { TextBotBlob } from "../../BotBlob";
import { OptionButton } from "../Option";
import { FieldRenderer } from "./FieldRenderer";

export type FormActionValues = {
  [key: string]: number | string | boolean | any;
};

export interface FormRendererProps {
  message: FormAction;
  onSubmit: ChatbotViewSubmitCallback | undefined;
  onCancel: ChatbotViewSubmitCallback | undefined;
}

export const FormRenderer: React.FC<FormRendererProps> = ({
  message,
  onSubmit,
  onCancel,
}) => {

  const schema = message.schema as FormActionSchema

  const [submitted, setSubmitted] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [error, setError] = useState("");

  const formMethods = useForm<FormActionValues>();

  const getDefaultValues = (message: FormAction) => {
    const values: FormActionValues = {};
    schema.fields.forEach((field, i) => {
      if (field.defaultValue) {
        values[field.key] = field.defaultValue;
      }
    });
    return values;
  };

  const [values, setValues] = useState<FormActionValues>(
    getDefaultValues(message)
  );

  const changeValue = (key: string, value: number | string | boolean) => {
    setValues((values) => ({
      ...values,
      [key]: value,
    }));
  };

  const submit = async (values: FormActionValues) => {
    if (!onSubmit) return;
    try {
      await onSubmit!(message.action, { values, resultVariable: message.resultVariable }, {showMessage: false});
      setSubmitted(true);
    } catch (error) {
      if ((error as AxiosError).isAxiosError && (error as AxiosError).response?.data.message) {
        setError(
          (error as AxiosError).response?.data.message
        );
      } else {
        setError(
          "Der skete desværre en fejl. Prøv igen senere."
        );
      }
    }
  };

  return (
    <Delayed waitBeforeShow={message.delay}>
      <FormProvider {...formMethods}>
        <Grid container className="BotBlob" id="BotBlobResize">
          {message.textBefore && (
            <TextBotBlob text={message.textBefore} delay={0} />
          )}
          <Grid item xs={12} style={{ marginTop: "5px" }}>
            <Grid container id="BotBlobPaper" direction="row" style={{ paddingBottom: 0 }}>
              {schema.fields.map((field, index) => (
                <Grid item xs={12} key={index} style={{ marginBottom: "10px" }}>
                  <FieldRenderer
                    field={field}
                    value={values[field.key]}
                    onChange={(v) => changeValue(field.key, v)}
                  />
                </Grid>
              ))}
              {error && (
                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                  <Typography color="error">{error}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <OptionButton option={{ label: schema.submitTitle, value: "submit" }} disabled={formMethods.formState.isSubmitting || submitted || cancelled} pressed={formMethods.formState.isSubmitting || submitted} submitOption={() => formMethods.handleSubmit(submit)()} />
          <OptionButton option={{ label: schema.cancelTitle, value: "cancel" }} disabled={formMethods.formState.isSubmitting || submitted || cancelled} submitOption={() => {
            setCancelled(true);
            onCancel && onCancel(schema.cancelTitle, undefined, {showMessage: false});
          }} />
        </Grid>
      </FormProvider>
    </Delayed>
  );
};
