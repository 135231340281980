import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

type ConfirmDeleteModalProps = {
    open: boolean;
    closeModal: () => void;
    handleDelete: ()=> Promise<void>;
}

class ConfirmDeleteModal extends Component<ConfirmDeleteModalProps, any> {
    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.closeModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Er du sikker på, at du vil slette samtalen?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Når du trykker “slet samtale” bliver samtalen og alt data forbundet med den slettet fra vores system. Du skal være opmærksom på,
                            at hvis du har anmodet om at få samtalen tilsendt, eller om at blive ringet op, kan disse aktioner blive annulleret.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.closeModal} color="secondary" autoFocus>
                            Annuller
                        </Button>
                        <Button onClick={this.props.handleDelete} color="primary" >
                            Slet samtale
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ConfirmDeleteModal;