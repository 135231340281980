import {FilterXSS, IFilterXSSOptions} from "xss";

const getSanitizeOptions = (): IFilterXSSOptions => {
    return {
        whiteList: {
            a: ['href', 'target'],
            b: [],
            i: [],
            u: [],
            ul: [],
            ol: [],
            li: [],
            br: []
        }
    };
};

const XSS = new FilterXSS(getSanitizeOptions());

export const Sanitize = (domNode: any) => {
    return XSS.process(domNode);
};