import React from 'react';
import './../BlobStyles.scss';
import axios, {AxiosRequestConfig} from "axios";
import Delayed from "../../utils/Delayed";
import Grid from "@material-ui/core/Grid";
import Logger from "../../../utils/Logger";
import {BotBlobProps} from "../BotBlob";
import {getTextForElement} from "../../../model/Dialog/Dialog";
import {DownloadIcon} from "./icons/DownloadIcon";

export const download = async (event: any, fileUrl: string|undefined) => {

    if (!fileUrl)
        return;

    let imageType = "image/png";
    let filename = fileUrl.split(/(\\|\/)/g).pop();

    if (fileUrl.indexOf(".jpg") > -1)
        imageType = "image/jpeg";
    else if (fileUrl.indexOf(".png") > -1)
        imageType = "image/png";
    else if (fileUrl.indexOf(".gif") > -1)
        imageType = "image/gif";
    else if (fileUrl.indexOf(".svg") > -1)
        imageType = "image/svg+xml";

    const options: AxiosRequestConfig = {
        headers: {
            'Content-Type': imageType,
            'Content-Disposition': 'attachment'
        }
    };

    try {
        const res = await axios.get(fileUrl, options);

        if(res && res.data) {
            const image = `data:${imageType};base64,${btoa(res.data)}`;

            const element = document.createElement('a');
            element.setAttribute('href', image);
            element.setAttribute('download', filename || "Billede.png");

            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    } catch (e) {
        Logger.error(`Failed downloading image`, e);
        window.open(fileUrl, "_blank");
    }
};

export const ImageElement = ({text, descr, source, delay}: BotBlobProps) => {
    if ((text || descr ) && (text !== "" || descr !== "")) {
        return (
            <Delayed waitBeforeShow={delay}>
                <Grid container spacing={1} direction="row" style={{ flexWrap: "nowrap" }}>
                    <Grid item>
                        <img className={"BotBlobImg responseImgWithText"} src={source} alt={getTextForElement(text, descr)}/>
                        <Grid className={"responseImgText"}>
                            <div className={"responseTextAlign"}>{getTextForElement(text, descr)}</div>
                        </Grid>
                    </Grid>
                    <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div onClick={async (e) => download(e, source)} >
                            <DownloadIcon/>
                        </div>
                    </Grid>
                </Grid>
            </Delayed>
        )
    } else {
        return (
            <Delayed waitBeforeShow={delay}>
                <Grid container spacing={1} direction="row" style={{ flexWrap: "nowrap" }}>
                    <Grid item>
                        <img className={"BotBlobImg responseImg"} src={source} alt={"Billede"}/>
                    </Grid>
                    <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div onClick={async (e) => download(e, source)} >
                            <DownloadIcon/>
                        </div>
                    </Grid>
                </Grid>
            </Delayed>
        )
    }
};