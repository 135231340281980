import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { HappyHeadIcon } from "./HappyHeadIcon";
import { MediumHeadIcon } from "./MediumHeadIcon";
import { RatingOverlay } from "./RatingOverlay";
import { SadHeadIcon } from "./SadHeadIcon";

export const RatingBlob: React.FC<{
  texts: {
    bad: string;
    medium: string;
    good: string;
  };
  onRated: (rating: { message: string; rating: number }) => void;
}> = ({ texts, onRated }) => {
  const [ratingOpen, setRatingOpen] = useState<1 | 2 | 3>(0 as any);

  return (
    <>
      <RatingOverlay
        isOpen={!!ratingOpen}
        initialRating={ratingOpen}
        onClose={(rating) => {
          setRatingOpen(0 as any);
          if (rating) {
            onRated(rating);
          }
        }}
        texts={texts}
      />
      <div className={"BotBlob"} style={{ marginTop: "16px" }}>
        <RatingHeads onClick={(rating) => setRatingOpen(rating)} />
      </div>
    </>
  );
};

export const RatingHeads: React.FC<{
  onClick: (rating: 1 | 2 | 3) => void;
  selected?: 1 | 2 | 3;
}> = ({ onClick, selected }) => {
  return (
    <Box display="flex" justifyContent="start">
      <Box marginRight={2.5}>
        <button
          tabIndex={0}
          className="blob-focusable"
          style={{
            display: "flex",
            flexDirection: "column",
            appearance: "none",
            background: "none",
            padding: 0,
            border: "none",
          }}
          onClick={() => onClick(1)}
        >
          <SadHeadIcon
            mute={selected && selected !== 1}
            active={selected === 1}
          />
          <Typography
            variant="caption"
            color="textSecondary"
            align="center"
            style={{ marginTop: "6px" }}
          >
            Dårlig
          </Typography>
        </button>
      </Box>
      <Box marginRight={2.5}>
        <button
          tabIndex={0}
          className="blob-focusable"
          style={{
            display: "flex",
            flexDirection: "column",
            appearance: "none",
            background: "none",
            padding: 0,
            border: "none",
          }}
          onClick={() => onClick(2)}
        >
          <MediumHeadIcon
            mute={selected && selected !== 2}
            active={selected === 2}
          />
          <Typography
            variant="caption"
            color="textSecondary"
            align="center"
            style={{ marginTop: "6px" }}
          >
            Okay
          </Typography>
        </button>
      </Box>
      <Box>
        <button
          tabIndex={0}
          className="blob-focusable"
          style={{
            display: "flex",
            flexDirection: "column",
            appearance: "none",
            background: "none",
            padding: 0,
            border: "none",
          }}
          onClick={() => onClick(3)}
        >
          <HappyHeadIcon
            mute={selected && selected !== 3}
            active={selected === 3}
          />
          <Typography
            variant="caption"
            color="textSecondary"
            align="center"
            style={{ marginTop: "6px" }}
          >
            God
          </Typography>
        </button>
      </Box>
    </Box>
  );
};
