import React, { Component } from "react";
import InputComponent from "../components/Input/InputComponent";
import ChatLog from "../components/ChatLog/ChatLog";
import "./ChatbotView.scss";
import { InitTypingBotBlob, TextBotBlob } from "../components/Message/BotBlob";
import UserBlob from "../components/Message/UserBlob";
import { APIService, RateDialog, SendDialog } from "../service/APIService";
import HeaderComponent from "../components/Header/HeaderComponent";
import {
  checkIfActiveFormAction,
  getFormAction,
  seeIfResponseExists,
  sortAnswers,
} from "../model/Dialog/Dialog";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { config } from "../config/config";
import Message from "../components/Message/Message";
import { DialogResponse } from "../model/API/response/DialogResponse";
import Logger from "../utils/Logger";
import {
  FormAction,
  FormatterResponseType,
  MessageElement,
} from "../model/API/MessageElement";
import PopupSnackbar from "../components/utils/PopupSnackbar";
import { Store } from "../model/Store/Store";
import StartDialogModal from "../components/Message/components/StartDialogModal/StartDialogModal";
import { FormActionValues } from "../components/Message/components/form";
import { AxiosError } from "axios";
import { TypingBotBlob } from "../components/Message/BotBlob";
import { BotMessage } from "../components/Message/Message";
import { ChatContextProvider } from "../components/ChatContext";

type ChatbotViewState = {
  blobs: any[];
  category: string;
  municipalityCode: number;
  placeholder?: string;
  chatDisabled: boolean;
  lastMessageTime: number;
  confirmationSent: boolean;
  botName: string;
  popup: any;
  isDialogDeleted: boolean;
  isFeedbackEnabled: boolean;
  actionLock: string;
  handleAction?: FormAction;
  ratingText: { bad: string; medium: string; good: string };
};

export type ChatbotViewSubmitCallback = (
  text: string,
  action?: { values: FormActionValues; resultVariable: string },
  options?: { showMessage: boolean }
) => Promise<void>;

export type ChatbotViewFeedbackCallback = (
  feedback: string,
  rating: number,
  tag?: string
) => Promise<void>;

class ChatbotView extends Component<
  RouteComponentProps<any>,
  ChatbotViewState
> {
  private service: APIService = new APIService();
  private sessionInvalidationTimeout: any;
  private checkIfActiveInterval: any;

  constructor(props: any) {
    super(props);

    this.state = {
      blobs: [],
      category: "",
      municipalityCode: 0,
      chatDisabled: false,
      lastMessageTime: 0,
      confirmationSent: false,
      botName: "Muni",
      popup: null,
      isDialogDeleted: false,
      isFeedbackEnabled: true,
      actionLock: "",
      ratingText: {
        bad: "Av for den... Hvad synes du vi kunne have gjort bedre?",
        medium: "Okay. Kan jeg gøre noget for, at blive bedre til næste gang?",
        good: "Wow! Sikke jeg kan 😊. Hvad gjorde jeg godt?",
      },
    };
  }

  private get dialogContext() {
    return {
      municipalityCode: this.state.municipalityCode,
      sessionId: Store.getSession(this.state.municipalityCode),
      customerId: Store.get(`customerId:${this.state.municipalityCode}`),
    };
  }

  private handleBotRes = async (botRes: DialogResponse) => {
    try {
      if (botRes.sessionId) {
        Store.storeSession(botRes.sessionId, this.state.municipalityCode);
      }

      if (botRes.customerId) {
        Store.storeCustomerId(botRes.customerId, this.state.municipalityCode);
      }

      this.setupSessionInvalidationHandler(botRes.sessionExpiredText);

      if (seeIfResponseExists(botRes)) {
        const ratingText = {
          bad: botRes.ratingText?.bad ?? this.state.ratingText.bad,
          medium: botRes.ratingText?.medium ?? this.state.ratingText.medium,
          good: botRes.ratingText?.good ?? this.state.ratingText.good,
        };
        this.setState({
          ...this.state,
          ratingText,
          isFeedbackEnabled:
            botRes.ratingEnabled ?? this.state.isFeedbackEnabled,
        });
        const blobsForAnswer = sortAnswers(
          botRes.msgElements,
          this.onSubmit,
          this.state.municipalityCode,
          this.sendFeedback,
          ratingText
        );
        this.setState({
          ...this.state,
          blobs: [
            ...this.state.blobs,
            <InitTypingBotBlob
              code={this.state.municipalityCode}
              pause={config.typingTime}
            />,
          ],
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            actionLock: checkIfActiveFormAction(botRes),
            handleAction: getFormAction(botRes),
            blobs: [...this.state.blobs, blobsForAnswer],
            placeholder: botRes.placeholder,
          });
        }, config.typingTime);
        Logger.debug("Chatbotview response:", botRes);
      } else {
        Logger.error(`could not find an answer for response:` + botRes);
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  /**
   * Sets up session invalidation with config.sessionExpire.
   *
   * Clears existing timeout if it exists. If session expires, it will add a message letting the user know.
   */
  private setupSessionInvalidationHandler = (
    expireText: string | undefined
  ) => {
    // Check if session timeout should be cleared
    if (this.sessionInvalidationTimeout)
      clearTimeout(this.sessionInvalidationTimeout);

    // Create timeout that invalidated session
    this.sessionInvalidationTimeout = setTimeout(() => {
      console.log(
        "Will expire session with message: " +
          (expireText || config.sessionExpiredText)
      );
      // Clear the store and add a bot message
      this.setState({
        ...this.state,
        blobs: [
          ...this.state.blobs,
          <BotMessage
            code={this.state.municipalityCode}
            blobs={[
              <TextBotBlob
                text={expireText || config.sessionExpiredText}
                delay={0}
              />,
            ]}
          />,
        ],
      });
      this.clearStore();
    }, config.sessionExpire);
  };

  private handleError = (error: Error | any) => {
    Logger.error(error);
    this.displayErrorMessage(
      "Jeg beklager der er opstået en teknisk fejl under samtalen - prøv igen senere."
    );
  };

  submitFormAction = async (
    actionName: string,
    action: { values: FormActionValues; resultVariable: string },
    showUserBlob?: string
  ) => {
    Logger.debug(`Submitted action ${actionName} with values:`, action.values);
    if (showUserBlob) {
      let userText = (
        <UserBlob
          text={showUserBlob.trim()}
          key={this.state.blobs.length + 1}
        />
      );
      await new Promise((resolve, _) =>
        this.setState(
          {
            ...this.state,
            blobs: [...this.state.blobs, <Message blobs={[userText]} />],
            lastMessageTime: new Date().getTime(),
          },
          () => resolve(true)
        )
      );
    }
    const typingBlobIndex = this.state.blobs.length;
    this.setState((s) => ({
      ...s,
      blobs: [
        ...s.blobs,
        <BotMessage
          code={this.state.municipalityCode}
          blobs={[<TypingBotBlob pause={5000} />]}
        />,
      ],
    }));

    try {
      // Send action to backend
      const botRes: DialogResponse = await this.service.sendUserAction({
        action: actionName,
        value: action.values,
        resultVariable: action.resultVariable,
        ...this.dialogContext,
      });
      // Process the response as normal
      this.setState((state) => {
        const blobs = [...state.blobs];
        if (typingBlobIndex < blobs.length) blobs.splice(typingBlobIndex, 1);
        return { ...state, handleAction: undefined, blobs };
      });
      await this.handleBotRes(botRes);
    } catch (error) {
      const err = error as AxiosError;
      if (
        err.isAxiosError &&
        err.response &&
        err.response.status >= 400 &&
        err.response.status <= 404
      ) {
        this.handleError(error);
      } else if (
        this.state.handleAction?.schema.schemaType === "integrated" &&
        err.isAxiosError &&
        err.response
      ) {
        // Integrated schemas should always report the error
        if (err.response.data?.message) {
          this.displayErrorMessage(err.response.data.message);
        } else {
          this.handleError(error);
        }
      } else {
        throw error;
      }
    } finally {
      setTimeout(() => {
        // Reset lock state
        this.setState({ ...this.state, chatDisabled: false });
      }, 500);
    }
  };

  unlockAction = async () => {
    if (!this.state.actionLock) return;

    const message = this.state.actionLock;
    let userText = (
      <UserBlob text={message.trim()} key={this.state.blobs.length + 1} />
    );

    this.setState({
      ...this.state,
      actionLock: "",
      handleAction: undefined,
      blobs: [...this.state.blobs, <Message blobs={[userText]} />],
      lastMessageTime: new Date().getTime(),
    });

    try {
      const botRes: DialogResponse = await this.service.sendUserMessage({
        msg: message,
        ...this.dialogContext,
      });
      this.handleBotRes(botRes);
    } catch (e) {
      this.handleError(e);
    }

    return;
  };

  validateIntegratedFormAction = (text: string) => {
    if (
      this.state.handleAction &&
      this.state.handleAction.schema.schemaType === "integrated"
    ) {
      const pattern = this.state.handleAction.schema.pattern;
      if (pattern && !new RegExp(pattern).test(text)) {
        this.displayErrorMessage(
          this.state.handleAction.schema.validationMessage ||
            "Jeg kunne ikke helt forstå det du skrev. Er du sikker på at du har skrevet det rigtigt?"
        );
        return false;
      }
    }
    return true;
  };

  onSubmit: ChatbotViewSubmitCallback = async (
    text: string,
    action,
    options = { showMessage: true }
  ) => {
    if (this.state.chatDisabled) return;

    this.setState({
      ...this.state,
      chatDisabled: true,
      lastMessageTime: new Date().getTime(),
    });

    if (action) {
      return this.submitFormAction(
        text,
        action,
        (options.showMessage && text) || undefined
      );
    }
    if (!this.state.actionLock && this.state.handleAction) {
      if (!this.validateIntegratedFormAction(text)) return;
      return this.submitFormAction(
        this.state.handleAction.action,
        {
          resultVariable: this.state.handleAction.resultVariable,
          values: {
            data: text,
          },
        },
        (options.showMessage && text) || undefined
      );
    }
    if (options.showMessage) {
      let userText = (
        <UserBlob text={text.trim()} key={this.state.blobs.length + 1} />
      );
      this.setState({
        ...this.state,
        blobs: [...this.state.blobs, <Message blobs={[userText]} />],
        lastMessageTime: new Date().getTime(),
      });
    }

    try {
      const botRes: DialogResponse = await this.service.sendUserMessage({
        msg: text,
        ...this.dialogContext,
      });
      this.handleBotRes(botRes);
    } catch (e) {
      this.handleError(e);
    }
    setTimeout(
      () => this.setState({ ...this.state, chatDisabled: false }),
      500
    );
  };

  sendDialogToUser = async (email: string) => {
    const req: SendDialog = {
      sessionId: Store.getSession(this.state.municipalityCode),
      customerId: Store.get(`customerId:${this.state.municipalityCode}`),
      municipalityCode: this.state.municipalityCode,
      mail: email,
    };
    Logger.debug(["Sending dialog", req]);
    await this.service.sendDialog(req);
  };

  addPopup = (text: string, type: string) => {
    this.setState({ ...this.state, popup: "" });
    this.setState({
      ...this.state,
      popup: <PopupSnackbar text={text} type={type} />,
    });
  };

  sendFeedback: ChatbotViewFeedbackCallback = async (comment, rating, tag) => {
    const rateReq: RateDialog = {
      municipalityCode: this.state.municipalityCode,
      comment,
      rating,
      tag,
      sessionId: Store.getSession(this.state.municipalityCode),
      customerId: Store.get(`customerId:${this.state.municipalityCode}`),
    };

    Logger.debug("Feedback received", rateReq);

    try {
      await this.service.rateDialog(rateReq);
      Store.storeFeedback(true, this.state.municipalityCode);
      const feedbackConfirmation = sortAnswers(
        [
          {
            responseType: FormatterResponseType.TEXT,
            text: "Tak for din vurdering!",
          },
        ],
        null,
        this.state.municipalityCode,
        this.sendFeedback,
        this.state.ratingText
      );
      this.setState({
        ...this.state,
        blobs: [...this.state.blobs, feedbackConfirmation],
        confirmationSent: true,
      });
    } catch (e) {
      const feedbackConfirmation = sortAnswers(
        [
          {
            responseType: FormatterResponseType.TEXT,
            text: "Der opstod en fejl under indsendelse af din feedback. Prøv venligst igen",
          },
        ],
        null,
        this.state.municipalityCode,
        this.sendFeedback,
        this.state.ratingText
      );
      this.setState({
        ...this.state,
        blobs: [...this.state.blobs, feedbackConfirmation],
      });
    }
  };

  deleteDialog = async (): Promise<void> => {
    const deleteDialogReq = {
      sessionId: Store.getSession(this.state.municipalityCode),
      customerId: Store.get(`customerId:${this.state.municipalityCode}`),
      municipalityCode: this.state.municipalityCode,
    };

    await this.service.deleteDialog(deleteDialogReq);
    this.clearStore();

    this.setState({ ...this.state, blobs: [], isDialogDeleted: true });
  };

  handleShowNewDialog = async () => {
    await this.setState({ ...this.state, isDialogDeleted: false });
    await this.initConversation();
  };

  clearStore = (): void => {
    Store.remove(this.state.municipalityCode, "sessionId");
    Store.remove(this.state.municipalityCode, "customerId");
    Store.remove(this.state.municipalityCode, "expire");
    Store.remove(this.state.municipalityCode, "feedback");
    Store.remove(this.state.municipalityCode, "feedback:expire");
  };

  setQueryParams = async () => {
    const search = new URLSearchParams(this.props.location.search);
    const category = search.get("category");
    const municipalityCode = search.get("municipalityCode");

    let botName = search.get("name");

    if (botName && botName.length > 22) botName = botName.substr(0, 22);

    this.setState({
      ...this.state,
      municipalityCode: municipalityCode
        ? parseInt(municipalityCode)
        : this.state.municipalityCode,
      category: category ? category : "",
      botName: botName ? botName : "Muni",
      isFeedbackEnabled: true,
    });
  };

  askUserForFeedback = () => {
    this.checkIfActiveInterval = setInterval(() => {
      let isActive = this.checkIfChatIsActive();
      if (
        this.state.isFeedbackEnabled &&
        !isActive &&
        !this.state.confirmationSent &&
        this.state.blobs.length > 2
      ) {
        Store.storeFeedback(true, this.state.municipalityCode);
        Logger.debug(
          "ChatbotView ,askForConfermation, Chat is inactive, sending message"
        );
        let confirmationBlob = (
          <div aria-hidden="true">
            {sortAnswers(
              [
                {
                  responseType: FormatterResponseType.TEXT,
                  // Math.floor(Math.random() * 3)
                  text: config.reviewPossibilities[0],
                },
                {
                  responseType: FormatterResponseType.REVIEW,
                  texts: this.state.ratingText,
                  tag: "Automatisk rating",
                },
              ],
              null,
              this.state.municipalityCode,
              this.sendFeedback,
              this.state.ratingText
            )}
          </div>
        );
        this.setState({
          ...this.state,
          lastMessageTime: new Date().getTime(),
          blobs: [...this.state.blobs, confirmationBlob],
          confirmationSent: true,
        });
        Logger.debug(
          "User has been asked for review, and will not be asked again."
        );
        clearInterval(this.checkIfActiveInterval);
      }
    }, 500);
  };

  checkIfChatIsActive = (): boolean => {
    if (
      new Date().getTime() - config.inactivityTimeInSeconds * 1000 >
      this.state.lastMessageTime
    ) {
      Logger.debug(
        "ChatbotView, checkIfChatIsActive, It has been too long since last confirmation"
      );
      return false;
    }

    return true;
  };

  initConversation = async (): Promise<void> => {
    let botRes: DialogResponse;
    this.setState({
      ...this.state,
      chatDisabled: true,
      lastMessageTime: new Date().getTime(),
    });

    try {
      if (this.state.category && this.state.category !== "") {
        botRes = await this.service.getCategoryMessage({
          category: this.state.category,
          municipalityCode: this.state.municipalityCode,
          sessionId: Store.getSession(this.state.municipalityCode),
        });
      } else {
        botRes = await this.service.sendUserMessage({
          msg: "",
          municipalityCode: this.state.municipalityCode,
          sessionId: Store.getSession(this.state.municipalityCode),
          customerId: Store.get(`customerId:${this.state.municipalityCode}`),
        });
      }

      this.handleBotResponseForInit(botRes);
    } catch (e) {
      Logger.error("Failed getting response with reason: ", e);
      this.displayErrorMessage(
        "Jeg beklager der er opstået en teknisk fejl under samtalen - prøv igen senere."
      );
      this.clearStore();
    }

    this.setState({ ...this.state, chatDisabled: false });
  };

  handleBotResponseForInit = (botRes: DialogResponse) => {
    if (botRes.hasOwnProperty("sessionId")) {
      Store.storeSession(botRes.sessionId, this.state.municipalityCode);
    }

    if (botRes.hasOwnProperty("customerId")) {
      Store.storeCustomerId(botRes.customerId, this.state.municipalityCode);
    }

    if (seeIfResponseExists(botRes)) {
      let blobsForAnswer = sortAnswers(
        botRes.msgElements,
        this.onSubmit,
        this.state.municipalityCode,
        this.sendFeedback,
        this.state.ratingText
      );
      this.setState({
        ...this.state,
        blobs: [
          ...this.state.blobs,
          <InitTypingBotBlob
            code={this.state.municipalityCode}
            pause={config.typingTime}
          />,
        ],
        placeholder: botRes.placeholder,
      });
      setTimeout(() => {
        this.setState({
          ...this.state,
          actionLock: checkIfActiveFormAction(botRes),
          handleAction: getFormAction(botRes),
          blobs: [...this.state.blobs, blobsForAnswer],
        });
      }, config.typingTime);
      Logger.debug(["Chatbotview response:", botRes]);
    } else {
      Logger.error(`could not find an answer for response:` + botRes);
    }
  };

  displayErrorMessage = (msg: string) => {
    const elements: MessageElement[] = [
      {
        responseType: FormatterResponseType.TEXT,
        text: msg,
        delay: 0,
      },
    ];
    let blobsForAnswer = sortAnswers(
      elements,
      null,
      this.state.municipalityCode,
      this.sendFeedback,
      this.state.ratingText
    );
    this.setState({
      ...this.state,
      blobs: [
        ...this.state.blobs,
        <InitTypingBotBlob
          code={this.state.municipalityCode}
          pause={config.typingTime}
        />,
      ],
    });
    setTimeout(() => {
      this.setState({
        ...this.state,
        blobs: [...this.state.blobs, blobsForAnswer],
      });
    }, config.typingTime);
  };

  componentDidMount = async (): Promise<void> => {
    // Set host randomly between two or more locations
    this.service.setHost(this.service.determineHost());

    try {
      await this.setQueryParams();
      await this.initConversation();
      setTimeout(async () => {
        await this.service.syncPresentationSettings(
          this.state.municipalityCode
        );
        await this.setState({ isFeedbackEnabled: config.isFeedbackEnabled });

        if (
          this.state.isFeedbackEnabled &&
          !Store.hasFeedback(this.state.municipalityCode)
        ) {
          this.askUserForFeedback();
        }
      }, 500);
    } catch (e) {
      this.displayErrorMessage(
        "Der er sket en fejl, prøv venligst igen senere."
      );
      Logger.error(`Error while setting up conversation`);
    }
  };

  render() {
    return (
      <ChatContextProvider
        municipalityCode={this.state.municipalityCode}
        sessionId={Store.getSession(this.state.municipalityCode)}
      >
        <div
          id={"mainDiv"}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="grid-header">
            <HeaderComponent
              sendDialog={this.sendDialogToUser}
              botName={this.state.botName}
              deleteDialog={this.deleteDialog}
              addPopup={this.addPopup}
            />
          </div>
          <div className="grid-center">
            <ChatLog blobs={this.state.blobs} />
          </div>
          <div className="grid-footer">
            <InputComponent
              disabled={this.state.chatDisabled}
              onSubmit={this.onSubmit}
              placeholder={
                this.state.placeholder ||
                (this.state.blobs.length > 3 && "Skriv her...") ||
                undefined
              }
            />
          </div>
          {this.state.popup}
          {this.state.isDialogDeleted && (
            <StartDialogModal
              open={this.state.isDialogDeleted}
              onShowNewDialog={this.handleShowNewDialog}
            />
          )}
        </div>
      </ChatContextProvider>
    );
  }
}

export default withRouter(ChatbotView);
