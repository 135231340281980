import Axios, { AxiosInstance } from "axios"


export class DAWAService {
  private static readonly apiUrl: string = "https://api.dataforsyningen.dk"

  private readonly apiClient: AxiosInstance = Axios.create({
    baseURL: DAWAService.apiUrl
  });

  public async autocomplete(query: string) {
    const res = await this.apiClient.get<DAWAAutocompleteResponse>(`/autocomplete?type=adresse&q=${encodeURIComponent(query)}`);
    return res.data;
  }

}

export type DAWAAutocompleteResponse = Array<{
  type: 'vejnavn' | 'adgangsadresse' | 'adresse';
  tekst: string;
  caretpos: number;
  forslagstekst: string;
  data: any;
}>