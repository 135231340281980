import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import { Option } from "../../../model/API/MessageElement";

export interface OptionButtonProps {
  option: Option;
  disabled?: boolean;
  submitOption: (value: string) => void;
  pressed?: boolean;
}

export const OptionButton: React.FC<OptionButtonProps> = (props) => {
  const [pressed, setPressed] = useState(props.pressed || false);

  useEffect(() => {
    if (props.pressed === undefined) return;
    setPressed(props.pressed);
  }, [props.pressed]);

  const bgcolor = useMemo(() => {
    return pressed
      ? "var(--main-bg-bot_blob_option_background_color)"
      : "white";
  }, [pressed]);

  const color = useMemo(() => {
    return pressed
      ? "var(--main-bg-bot_blob_option_click_text_color)"
      : "var(--main-bg-bot_blob_option_text_color)";
  }, [pressed]);

  const handleButtonClick = (text: string) => {
    if (props.pressed === undefined) {
      // If uncontrolled, we are pressed
      setPressed(true);
    }
    props.submitOption(text);
  };

  return (
    <Button
      disabled={props.disabled || false}
      tabIndex={0}
      variant={"text"}
      size={"medium"}
      key={props.option.label}
      style={{ backgroundColor: bgcolor, color: color }}
      className="BotBlobOption blob-focusable"
      onClick={(e) => handleButtonClick(props.option.value)}
    >
      {props.option.label ? props.option.label.trim() : ""}
    </Button>
  );
};
