import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

type StartDialogModalProps = {
    open: boolean,
    onShowNewDialog: () => any
}

class StartDialogModal extends Component<StartDialogModalProps, any> {

    render() {
        return (
            <Dialog
                disableBackdropClick={true}
                open={this.props.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Start ny samtale?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Du kan starte en ny samtale via knappen her.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onShowNewDialog.bind(this)} color="primary" autoFocus>
                         Start samtale
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}
export default StartDialogModal;