import * as React from "react";
import {Component} from "react";
import {Avatar791Icon} from "./avatar/Avatar791Icon";
import {AvatarIcon} from "./avatar/AvatarIcon";

type AvatarFinderProps = {
  code?: number;
};

export class AvatarFinder extends Component<AvatarFinderProps>{

    getIcon() {
        switch (this.props.code) {
            case 791:
                return <Avatar791Icon />;
            default:
                return <AvatarIcon />;
        }
    }

    render() {
        return(this.getIcon());
    }

}