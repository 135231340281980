import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { BotBlobTimestamp } from "./BotBlob";
import moment from "moment";
import Logger from "../../utils/Logger";
import { AvatarFinder } from "./components/icons/AvatarFinder";

type MessageProps = {
  code?: number;
  blobs: any[];
};

export const Message = (props: MessageProps) => {
  return (
    <Grid container direction="column">
      <Grid item xs={2}></Grid>
      {props.blobs}
    </Grid>
  );
};

export class BotMessage extends Component<MessageProps> {
  render() {
    Logger.debug("BotMessage", this.props.blobs);
    return (
      <Grid
        container
        direction="row"
        spacing={1}
        style={{ flexWrap: "nowrap" }}
      >
        <Grid item aria-hidden="true" tabIndex={-1}>
          <AvatarFinder code={this.props.code} />
        </Grid>
        <Grid
          item
          id={"MessageContainer"}
          aria-live="polite"
          aria-atomic="true"
          tabIndex={0}
        >
          <Grid item xs={12}>
            <span className="sr-only">
              Modtaget klokken {moment().format("HH:mm")} fra chatbot.{" "}
            </span>
            {this.props.blobs.map((item, index) => {
              return <div key={index}>{item}</div>;
            })}
          </Grid>
          <Grid item xs={12} aria-hidden="true" tabIndex={-1}>
            <BotBlobTimestamp timestamp={moment().format("HH:mm")} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Message;
