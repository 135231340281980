import React, { createContext, PropsWithChildren, useContext } from "react"

export interface IChatContext {
  municipalityCode: number;
  sessionId?: string;
}

export const ChatContext = createContext<IChatContext>(null!);

export interface ChatContextProviderProps extends IChatContext, PropsWithChildren<{}> { }

export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({ municipalityCode, sessionId, children }) => {
  return <ChatContext.Provider value={{ municipalityCode, sessionId }}>
    {children}
  </ChatContext.Provider>
}

export const useChatContext = () => {
  return useContext(ChatContext);
}
