import React, { Component } from "react";
import { Grid } from "@material-ui/core";

export class ChatLog extends Component<ChatLogProps> {
  private scrollContainer: React.RefObject<HTMLDivElement>;

  constructor(props: ChatLogProps) {
    super(props);

    this.scrollContainer = React.createRef<HTMLDivElement>();
  }

  scrollToBottom() {
    this.scrollContainer.current!.scrollIntoView({ behavior: "smooth" });
  }

  componentDidUpdate(
    prevProps: Readonly<ChatLogProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    // Timeout to ensure that element has loaded before scrolling.
    setTimeout(() => {
      this.scrollToBottom();
    }, 300);

    // Timeout to ensure that we scroll fully to the bottom of page.
    setTimeout(() => {
      this.scrollToBottom();
    }, 700);
  }

  render() {
    return (
      <Grid container className="chatLog" direction="column">
        <div className="scrollableLog" id={"scrollLog"}>
          <br />
          {this.props.blobs.map((blob: any, index: number) => {
            return <div key={index}>{blob}</div>;
          })}
        </div>
        <div id="scroll" style={{ clear: "both" }} ref={this.scrollContainer} />
      </Grid>
    );
  }
}

type ChatLogProps = {
  blobs: any;
  rest?: any;
  className?: any;
  dialog?: any;
};

export default ChatLog;
