import {
  ExpandBlob,
  ImageBlob,
  LinkBlob,
  OptionsBotBlob,
  TextBotBlob,
  TypingBotBlob,
  VideoBlob,
} from "../../components/Message/BotBlob";
import React, { ReactNode } from "react";
import { DialogResponse } from "../API/response/DialogResponse";
import { BotMessage } from "../../components/Message/Message";
import Delayed from "../../components/utils/Delayed";
import Logger from "../../utils/Logger";
import {
  FormAction,
  FormatterResponseType,
  MessageElement,
  Review as ReviewMessageElement,
} from "../API/MessageElement";
import { FormRenderer } from "../../components/Message/components/form/FormRenderer";
import { Grid } from "@material-ui/core";
import {
  ChatbotViewFeedbackCallback,
  ChatbotViewSubmitCallback,
} from "../../views/ChatbotView";
import { RatingBlob } from "../../components/Message/components/rating";
import { WatsonDiscoveryResultBlob } from "../../components/Message/components/WatsonDiscoveryResultBlob";

export const seeIfResponseExists = (botRes: DialogResponse) => {
  return botRes && botRes.msgElements && botRes.msgElements.length > 0;
};

export const getFormAction = (
  botRes: DialogResponse
): FormAction | undefined => {
  if (!botRes || !botRes.msgElements) return undefined;
  const msgElem = botRes.msgElements.find(
    (m) => m.responseType === FormatterResponseType.FORM_ACTION
  ) as FormAction;
  return msgElem;
};

export const checkIfActiveFormAction = (botRes: DialogResponse) => {
  const msgElem = getFormAction(botRes);
  if (
    !msgElem ||
    !msgElem.schema ||
    msgElem.schema.schemaType !== "form" ||
    !msgElem.schema.cancelTitle
  )
    return "";
  return msgElem.schema.cancelTitle;
};

export const sortAnswers = (
  genericBotAnswer: MessageElement[],
  handleClick: ChatbotViewSubmitCallback | null,
  code?: number,
  handleRating?: ChatbotViewFeedbackCallback,
  defaultRatingText?: { bad: string; medium: string; good: string }
) => {
  let botBlobs: ReactNode[] = [];
  Logger.debug(["Dialog.tsx, sortAnswers", genericBotAnswer]);
  let accumulatedDelay = 0;
  for (let answer of genericBotAnswer) {
    // Check to see if there exist a delay on the answer and create a delay between the blobs
    if (answer.delay && answer.delay > 0) {
      accumulatedDelay += answer.delay;
    }

    switch (answer.responseType) {
      case FormatterResponseType.OPTION:
        botBlobs.push(
          <OptionsBotBlob
            text={getTextForElement(answer.title, answer.description)}
            options={answer.options}
            handleClick={handleClick || undefined}
            delay={accumulatedDelay}
          />
        );
        break;
      case FormatterResponseType.PAUSE:
        break;
      case FormatterResponseType.TEXT:
        botBlobs.push(
          <TextBotBlob text={answer.text} delay={accumulatedDelay} />
        );
        break;
      case FormatterResponseType.BUTTONS:
        break;
      case FormatterResponseType.LINK:
        botBlobs.push(
          <LinkBlob
            text={getTextForElement(answer.title, answer.description)}
            source={answer.src}
            delay={accumulatedDelay}
          />
        );
        break;
      case FormatterResponseType.IMAGE:
        botBlobs.push(
          <ImageBlob
            text={answer.title}
            descr={answer.description}
            source={answer.src}
            delay={accumulatedDelay}
          />
        );
        break;
      case FormatterResponseType.VIDEO:
        botBlobs.push(
          <VideoBlob
            text={answer.title}
            source={answer.src}
            descr={answer.description}
            delay={accumulatedDelay}
          />
        );
        break;
      case FormatterResponseType.EXPANDER:
        botBlobs.push(
          <ExpandBlob
            expandables={answer.expandables}
            textBefore={answer.textBefore}
            textAfter={answer.textAfter}
            delay={accumulatedDelay}
          />
        );
        break;
      case FormatterResponseType.REVIEW:
        botBlobs.push(
          <RatingBlob
            texts={{ ...defaultRatingText, ...answer.texts }}
            onRated={(rating) => {
              handleRating &&
                handleRating(
                  rating.message,
                  rating.rating,
                  (answer as ReviewMessageElement).tag
                );
            }}
          />
        );
        // botBlobs.push(<ReviewBlob onSubmitReview={handleRating} />);
        break;
      case FormatterResponseType.FORM_ACTION:
        if (answer.schema.schemaType === "form")
          botBlobs.push(
            <FormRenderer
              message={answer}
              onSubmit={handleClick || undefined}
              onCancel={handleClick || undefined}
            />
          );
        if (answer.schema.schemaType === "integrated") {
          botBlobs.push(
            <Grid container className="BotBlob" id="BotBlobResize">
              {answer.schema.messageBefore && (
                <TextBotBlob
                  text={answer.schema.messageBefore}
                  delay={accumulatedDelay}
                />
              )}
              {answer.schema.resultType === "options" ? (
                <OptionsBotBlob
                  options={answer.schema.options}
                  handleClick={handleClick || undefined}
                  delay={accumulatedDelay}
                />
              ) : (
                <></>
              )}
            </Grid>
          );
        }
        break;
      case FormatterResponseType.WD_RESULTS:
        botBlobs.push(
          <WatsonDiscoveryResultBlob message={answer} delay={answer.delay} />
        );
        break;
      default:
        break;
    }
  }
  if (accumulatedDelay > 0) {
    botBlobs.push(
      <Delayed waitBeforeShow={0}>
        <TypingBotBlob pause={accumulatedDelay} />
      </Delayed>
    );
  }
  return <BotMessage code={code} blobs={botBlobs} />;
};

export const getTextForElement = (
  title: string | undefined,
  description: string | undefined
): string => {
  if (title && description && title !== "" && description !== "") {
    return `${title} - ${description}`;
  } else if (title && (!description || description === "")) {
    return title;
  } else {
    return description || "";
  }
};
