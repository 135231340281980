import ReactDOM from "react-dom";
import React, { useState, useEffect } from "react";

export const Portal: React.FC = ({ children }) => {
  const [container] = useState(() => {
    const el = document.createElement("div");
    el.classList.add("portal");
    return el;
  });

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
};
