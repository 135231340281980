import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

type RouterProps = {}

export default class AppRouter extends React.Component<RouterProps>{
    render(){
        return(
          <Router>
              <Route path="/">
                  {this.props.children}
              </Route>
          </Router>
        );
    }
}