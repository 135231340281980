export interface FormActionSchema {
  schemaType: "form";
  submitTitle: string;
  cancelTitle: string;
  fields: FormField[];
}

export type FormField = ITextField | IAddressTextField | INumberField;

export enum FormFieldType {
  TEXT_FIELD = "text",
  ADDRESS_FIELD = "address",
  NUMBER_FIELD = "number",
}

interface BaseFormField {
  key: string; // The key for this value. There should be no key conflicts (duplicates).
  defaultValue?: string | number | boolean;
  type: FormFieldType;
  required?: boolean;
}

export interface ITextField extends BaseFormField {
  type: FormFieldType.TEXT_FIELD;
  label: string;
  placeholder?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
}

export interface IAddressTextField extends BaseFormField {
  type: FormFieldType.ADDRESS_FIELD;
  label: string;
  placeholder?: string;
  dawaTypes?: Array<("vejnavn" | "adgangsadresse" | "adresse")>;
}

export interface INumberField extends BaseFormField {
  type: FormFieldType.NUMBER_FIELD;
  label: string;
  placeholder?: string;
  min?: number;
  max?: number;
}
