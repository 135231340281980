import React from 'react'
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "@material-ui/core";
import {DownIcon} from "./icons/DownIcon";
import Grid from "@material-ui/core/Grid";
import {Sanitize} from "../../utils/Sanitizer";

export const ExpandButton = (props: any) => {
    return (
        <Grid item>
        <ExpansionPanel id="BotBlobExpand">
            <ExpansionPanelSummary
            expandIcon={ <DownIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header">

            <div className="MuiExpansionPanelSummary-content">{props.item.label}</div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails id="BotBlobExpandDetail">
                <div dangerouslySetInnerHTML={{__html: Sanitize(props.item.value) || ""}} ></div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        </Grid>
    )
};

export default ExpandButton;