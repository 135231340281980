import React, { EventHandler, MouseEvent, useMemo } from "react";
import "./BlobStyles.scss";
import Expire from "../utils/Expire";
import { OptionButton } from "./components/Option";
import { LinkButton } from "./components/Link";
import Grid from "@material-ui/core/Grid";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Sanitize } from "../utils/Sanitizer";
import Delayed from "../utils/Delayed";
import ExpandButton from "./components/Expandable";
import { ImageElement } from "./components/Image";
import { getTextForElement } from "../../model/Dialog/Dialog";
import { AvatarFinder } from "./components/icons/AvatarFinder";
import { ChatbotViewFeedbackCallback, ChatbotViewSubmitCallback } from "../../views/ChatbotView";
import { Option } from "../../model/API/MessageElement";
import { Box } from "@material-ui/core";
import { APIService } from "../../service/APIService";
import { useChatContext } from "../ChatContext";

export type BotBlobProps = {
  code?: number;
  text?: string;
  options?: Array<Option & { divide?: true }>;
  pause?: number;
  timestamp?: string;
  handleClick?: ChatbotViewSubmitCallback;
  descr?: string;
  source?: string;
  links?: string;
  delay?: number;
  expandables?: any[];
  textBefore?: string;
  textAfter?: string;
  onSubmitReview?: ChatbotViewFeedbackCallback;
};

export const TextBotBlob = ({ text, delay }: BotBlobProps) => {
  return (
    <Delayed waitBeforeShow={delay}>
      <Grid container className={"BotBlob"}>
        <Grid item xs={12} id={"BotBlobResize"}>
          <div id="BotBlobPaper" dangerouslySetInnerHTML={{ __html: Sanitize(text) || "" }} />
        </Grid>
      </Grid>
    </Delayed>
  );
};

export const BotBlobTimestamp = ({ timestamp }: BotBlobProps) => {
  return <div id="timestamp">{timestamp ? timestamp : ""}</div>;
};

export const OptionsBotBlob = ({ options, handleClick, text, delay }: BotBlobProps) => {
  let optionsArr = [];
  if (options) {
    let count = 0;
    for (let option of options) {
      optionsArr.push(
        option.divide ? (
          <Box width="100%" key={count} height={8} />
        ) : (
          <Grid item key={count}>
            <OptionButton
              option={option}
              submitOption={(value) => handleClick && handleClick(value, undefined, { showMessage: false })}
            />
          </Grid>
        )
      );
      count++;
    }
  }
  return (
    <Delayed waitBeforeShow={delay}>
      <Grid container className="BotBlob" id={"BotBlobResize"}>
        {!text ? (
          ""
        ) : (
          <Grid item xs={12}>
            <div id="BotBlobPaper" dangerouslySetInnerHTML={{ __html: Sanitize(text) || "" }} />
          </Grid>
        )}
        <span className="sr-only">
          Liste af {optionsArr.length} {optionsArr.length !== 1 ? "knapper" : "knap"}.
        </span>
        <div className="BotBlobOptions">{optionsArr}</div>
      </Grid>
    </Delayed>
  );
};

export const LinkBlob = ({
  text,
  source,
  delay,
  collectionId,
  query,
  correlationId,
}: BotBlobProps & { collectionId?: string; query?: string; correlationId?: string }) => {
  const api = useMemo(() => new APIService(), []);
  const chat = useChatContext();

  /**
   * Handle click will post a statistical event to the backend for stat. purposes.
   */
  const handleClick: EventHandler<MouseEvent<HTMLAnchorElement>> = (event) => {
    // This should not await - it should just happen and block user interactions.
    api.postStatisticalEvent({
      eventType: "link-click",
      municipalityCode: chat.municipalityCode,
      sessionId: chat.sessionId!,
      metadata: {
        url: source,
        query,
        collectionId: collectionId,
        correlationId,
      },
    });
    return true;
  };

  return (
    <Delayed waitBeforeShow={delay}>
      <a
        className="blob-focusable"
        style={{ display: "inline-block" }}
        tabIndex={0}
        onClick={handleClick}
        href={source}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Grid
          container
          className="BotBlob"
          id={"BotBlobResize"}
          direction="row"
          style={{ flexWrap: "nowrap", width: "auto" }}
        >
          <Grid item>
            <div className="BotBlobLink" dangerouslySetInnerHTML={{ __html: Sanitize(text) || "" }} />
          </Grid>
          <Grid item>
            <LinkButton />
          </Grid>
        </Grid>
      </a>
    </Delayed>
  );
};

export const ImageBlob = ({ text, descr, source, delay }: BotBlobProps) => {
  return <ImageElement text={text} descr={descr} source={source} delay={delay} />;
};

export const VideoBlob = ({ text, source, descr, delay }: BotBlobProps) => {
  return (
    <Delayed waitBeforeShow={delay}>
      <Grid item xs={10}>
        <Grid className="BotBlob" container>
          <iframe className={"responseVid"} src={source} title="chat video" />
          <Grid className={"responseVidText"}>
            <div className={"responseTextAlign"}>{getTextForElement(text, descr)}</div>
          </Grid>
        </Grid>
      </Grid>
    </Delayed>
  );
};

export const ExpandBlob = ({ expandables, textBefore, textAfter, delay, handleClick }: BotBlobProps) => {
  let expandArr = [];
  if (expandables) {
    for (let item of expandables) {
      expandArr.push(
        <Grid key={item.text}>
          <ExpandButton item={item} />
        </Grid>
      );
    }
  }

  return (
    <Delayed waitBeforeShow={delay}>
      <Grid container className="BotBlob">
        <Grid id="BotBlobExpandPaper" item>
          {textBefore ? <div id="beforeexpand" dangerouslySetInnerHTML={{ __html: Sanitize(textBefore) || "" }} /> : ""}
          {expandArr}
          {textAfter ? <div id="afterexpand" dangerouslySetInnerHTML={{ __html: Sanitize(textAfter) || "" }} /> : ""}
        </Grid>
      </Grid>
    </Delayed>
  );
};

export const TypingBotBlob = ({ pause }: BotBlobProps) => {
  return (
    <Expire delay={pause}>
      <Grid container className="BotBlob">
        <Grid item xs={11}>
          <div className={"Blinking TypingBotBlob"}>
            <span>
              <FiberManualRecordIcon id={"typingDot"} />
            </span>{" "}
            <span>
              <FiberManualRecordIcon id={"typingDot"} />
            </span>{" "}
            <span>
              <FiberManualRecordIcon id={"typingDot"} />
            </span>
          </div>
        </Grid>
      </Grid>
    </Expire>
  );
};

export const InitTypingBotBlob = ({ pause, code }: BotBlobProps) => {
  return (
    <Expire delay={pause}>
      <Grid container direction="row" spacing={1} style={{ flexWrap: "nowrap" }} aria-hidden="true">
        <Grid item aria-hidden="true" tabIndex={-1}>
          <AvatarFinder code={code} />
        </Grid>
        <Grid item id={"MessageContainer"}>
          <Grid item xs={12}>
            <span key="0">
              <Grid container className="BotBlob">
                <Grid item xs={11}>
                  <div className={"Blinking TypingBotBlob"}>
                    <span>
                      <FiberManualRecordIcon id={"typingDot"} />
                    </span>{" "}
                    <span>
                      <FiberManualRecordIcon id={"typingDot"} />
                    </span>{" "}
                    <span>
                      <FiberManualRecordIcon id={"typingDot"} />
                    </span>
                  </div>
                </Grid>
              </Grid>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Expire>
  );
};
