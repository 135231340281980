import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CircularProgress from "@material-ui/core/CircularProgress";

type SaveDialogModalProps = {
    open: boolean;
    toggleClose: () => void;
    sendEmail: () => void;
    onEmailChange: (e: any) => void;
    isValid: boolean;
    isLoading: boolean;
}

class SaveDialogModal extends Component<SaveDialogModalProps, any> {
    render() {
        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.toggleClose} aria-labelledby="form-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="form-dialog-title">Modtag samtale</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            For at få tilsendt din samtale har vi brug for den e-mailadresse du ønsker samtalen sendt til.
                            <br/>
                            Vær opmærksom på, at der kan gå op til en time inden mailen modtages.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Din email"
                            type="email"
                            fullWidth
                            onChange={this.props.onEmailChange}
                            error={!this.props.isValid}
                            helperText="Indtast en gyldig email hvortil samtalen skal sendes"
                            inputProps={{
                                startadornment:(
                                    <InputAdornment position="start">
                                        <MailOutlineIcon/>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.toggleClose} color="secondary">
                            Annuller
                        </Button>
                        <div>
                            <Button onClick={this.props.sendEmail} color="primary" autoFocus>
                                Send samtale
                            </Button>
                            {this.props.isLoading && <CircularProgress size={24}/>}
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default SaveDialogModal;