import React from 'react';
import './App.scss';
import ChatbotView from "./views/ChatbotView";
import AppRouter from "./AppRouter";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import cssVars from "css-vars-ponyfill";

cssVars({});

const theme = createMuiTheme({
    typography: {
        fontSize: 12,
        fontFamily: "inherit"
    },
});

function App() {
  return (
    <div className="App">
        <ThemeProvider theme={theme}>
        <AppRouter>
            <ChatbotView/>
        </AppRouter>
        </ThemeProvider>
    </div>
  );
}

export default App;