import { FormActionIntegratedSchema } from "./FormActionIntegratedSchema";
import { FormActionSchema } from "./FormActionSchema";

export type MessageElement =
  | Image
  | Video
  | Text
  | Link
  | Options
  | Pause
  | Expander
  | Buttons
  | Review
  | FormAction
  | WatsonDiscoveryResultsMessage;

export enum FormatterResponseType {
  TEXT = "text",
  BUTTONS = "buttons",
  IMAGE = "image",
  VIDEO = "video",
  OPTION = "option",
  LINK = "link",
  PAUSE = "pause",
  EXPANDER = "expander",
  REVIEW = "review",
  FORM_ACTION = "form-action",
  WD_RESULTS = "wd-results",
}

export interface MessageBaseElement {
  responseType: FormatterResponseType;
  delay?: number;
}

export interface Image extends MessageBaseElement {
  responseType: FormatterResponseType.IMAGE;
  title: string;
  src: string;
  description: string;
}

export interface Video extends MessageBaseElement {
  responseType: FormatterResponseType.VIDEO;
  title: string;
  src: string;
  description: string;
}

export interface Text extends MessageBaseElement {
  responseType: FormatterResponseType.TEXT;
  text: string;
}

export interface Link extends MessageBaseElement {
  responseType: FormatterResponseType.LINK;
  type: string;
  title: string;
  description: string;
  src: string;
}

export interface Options extends MessageBaseElement {
  responseType: FormatterResponseType.OPTION;
  title: string;
  description: string;
  options: Option[];
}

export interface Option {
  label: string;
  value: string;
}

export interface Pause extends MessageBaseElement {
  responseType: FormatterResponseType.PAUSE;
  typing: boolean;
  delay: number;
}

export interface Expander extends MessageBaseElement {
  responseType: FormatterResponseType.EXPANDER;
  textBefore?: string;
  textAfter?: string;
  expandables: Option[];
}

// Buttons are not yet defined by us, and should not yet be implemented in the formatter pipe.
export interface Buttons extends MessageBaseElement {
  responseType: FormatterResponseType.BUTTONS;
  title: string;
  description: string;
  buttons: any[];
}

export interface Review extends MessageBaseElement {
  responseType: FormatterResponseType.REVIEW;
  texts: {
    bad: string;
    medium: string;
    good: string;
  };
  tag?: string;
}

export interface FormAction extends MessageBaseElement {
  responseType: FormatterResponseType.FORM_ACTION;
  textBefore?: string;
  action: string; // This is the action that should be initiated at the backend.
  schema: FormActionSchema | FormActionIntegratedSchema;
  resultVariable: string;
}

export interface WatsonDiscoveryResultsMessage extends MessageBaseElement {
  responseType: FormatterResponseType.WD_RESULTS;
  results: {
    collectionId: string;
    query: string;
    title: string;
    passage: string;
    link: string;
    correlationId: string;
  }[];
}
