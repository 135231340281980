import { Grid } from "@material-ui/core";
import React from "react";
import { WatsonDiscoveryResultsMessage } from "../../../model/API/MessageElement";
import Delayed from "../../utils/Delayed";
import { LinkBlob } from "../BotBlob";
import styles from "./WatsonDiscoveryResultBlob.module.scss";

export interface WatsonDiscoveryResultBlobProps {
  message: WatsonDiscoveryResultsMessage;
  delay?: number;
}

export function WatsonDiscoveryResultBlob({
  message,
  delay,
}: WatsonDiscoveryResultBlobProps) {
  return (
    <Delayed waitBeforeShow={delay}>
      <Grid container spacing={1}>
        {message.results.map((result, i) => (
          <Grid item key={i} style={{ maxWidth: "100%" }}>
            <Grid container className={`BotBlob`}>
              <Grid
                item
                xs={12}
                id="BotBlotResize"
                className={styles.container}
              >
                <div
                  id="BotBlobPaper"
                  style={{ maxWidth: "calc(100% - 21px)" }}
                >
                  <div className={styles.title}>{result.title}</div>
                  <div className={styles.link}>
                    {result.link.replace("https://", "")}
                  </div>
                  <div
                    className={styles.passage}
                    dangerouslySetInnerHTML={{
                      __html: result.passage || "",
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <LinkBlob
              text="Gå til siden (link)"
              delay={0}
              source={result.link}
              collectionId={result.collectionId}
              query={result.query}
              correlationId={result.correlationId}
            />
          </Grid>
        ))}
      </Grid>
    </Delayed>
  );
}
